import React from "react"
import Layout from "../components/layout"
import FadeInTitle from "../components/fade-in-title"
import AnimatedElement from "../components/animated-element"
import { Link } from "gatsby"
import EmailForm from "../components/email-form.js"
import "../stylesheets/pages/index.css"
import { Link as LinkScroll } from "react-scroll"
import Footer from "../components/footer.js"
import Helmet from "react-helmet"

export default function Home() {
  return (
    <Layout>
      <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>DH Labs</title>
            <meta name="title" content="DH Labs | MVP Development in USA" />
            <meta
               name="description"
               content="We specialize in creating Minimum Viable Products that help you get your first customers and/or raise funding without the costs of a full scale software shop."
            />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://dhlabs.io/" />
            <meta property="og:title" content="DH Labs | MVP Development in USA" />
            <meta
               property="og:description"
               content="We specialize in creating Minimum Viable Products that help you get your first customers and/or raise funding without the costs of a full scale software shop."
            />
            <meta property="og:image" content="https://dhlabs.io/MetaImage.png" />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://dhlabs.io/" />
            <meta property="twitter:title" content="DH Labs | MVP Development in USA" />
            <meta
               property="twitter:description"
               content="We specialize in creating Minimum Viable Products that help you get your first customers and/or raise funding without the costs of a full scale software shop.."
            />
            <meta property="twitter:image" content="https://dhlabs.io/MetaImage.png" />

            {/* <!-- Favicomatic -->  */}

            <link rel="apple-touch-icon-precomposed" sizes="57x57" href="apple-touch-icon-57x57.png" />
            <link rel="apple-touch-icon-precomposed" sizes="114x114" href="apple-touch-icon-114x114.png" />
            <link rel="apple-touch-icon-precomposed" sizes="72x72" href="apple-touch-icon-72x72.png" />
            <link rel="apple-touch-icon-precomposed" sizes="144x144" href="apple-touch-icon-144x144.png" />
            <link rel="apple-touch-icon-precomposed" sizes="60x60" href="apple-touch-icon-60x60.png" />
            <link rel="apple-touch-icon-precomposed" sizes="120x120" href="apple-touch-icon-120x120.png" />
            <link rel="apple-touch-icon-precomposed" sizes="76x76" href="apple-touch-icon-76x76.png" />
            <link rel="apple-touch-icon-precomposed" sizes="152x152" href="apple-touch-icon-152x152.png" />
            <link rel="icon" type="image/png" href="favicon-196x196.png" sizes="196x196" />
            <link rel="icon" type="image/png" href="favicon-96x96.png" sizes="96x96" />
            <link rel="icon" type="image/png" href="favicon-32x32.png" sizes="32x32" />
            <link rel="icon" type="image/png" href="favicon-16x16.png" sizes="16x16" />
            <link rel="icon" type="image/png" href="favicon-128.png" sizes="128x128" />
            <meta name="application-name" content="&nbsp;"/>
            <meta name="msapplication-TileColor" content="#FFFFFF" />
            <meta name="msapplication-TileImage" content="mstile-144x144.png" />
            <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
            <meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
            <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
            <meta name="msapplication-square310x310logo" content="mstile-310x310.png" />

        </Helmet>
      <div className="homeContainer mob">
        <div id="homeTop" className="mob">
          <h1>
            <FadeInTitle text={"We help early stage"} />
            <FadeInTitle text={"businesses get"} delay={0.3} />
            <FadeInTitle text={"their software ideas"} delay={0.4} />
            <FadeInTitle text={"rolling fast."} delay={0.5} />
          </h1>
          <p>
            We specialize in creating Minimum Viable Products that help you get
            your first customers and/or raise funding without the costs of a full
            scale software shop.
          </p>

          <div id="scrollContainer">
            <LinkScroll to="homeMonitor" spy={true} smooth={true} offset={-50} duration={500}>
              <div id="scrollIndicator" className="mob"/>
            </LinkScroll>
          </div>
        </div>

        <div id="homeMonitor" className="mob">
          <h1>Define. Design. Develop.</h1>
          <AnimatedElement elem={
          <div className="monitorContent mob">
            <div className="monitorIcons mob">
              <img src="../../monitor1.svg"/>
            </div>
            <div className="monitorDescriptions mob">
              <h2>Web Apps.</h2>
              <p>
                Simple. Serverless. Scalable. <br/>
                Web apps are our bread and butter.<br/>
                Let's get one started.
              </p>
            </div>
          </div>} onScroll={true} duration={0.5} delay={0.3} slideFromDirection={"left"} fadeIn={true}/>
        <AnimatedElement elem={
          <div id="centerContent" className="monitorContent mob">
            <div className="monitorIcons mob">
              <img src="../../mobile.svg"/>
            </div>
            <div className="monitorDescriptions mob">
              <h2>Mobile Apps.</h2>
              <p>
                iPhone? Android? Both?<br/>
                We use React Native to make sure<br/>
                you're covered no matter what.
              </p>
            </div>
          </div>
      } onScroll={true} duration={0.5} delay={0.3} slideFromDirection={"left"} fadeIn={true}/>
        <AnimatedElement elem={
          <div className="monitorContent mob" style={{marginBottom: "0"}}>
            <div className="monitorIcons mob">
              <img src="../../wireframe.svg"/>
            </div>
            <div className="monitorDescriptions mob">
              <h2>Mock-Ups.</h2>
              <p>
                Don't need a fully working app?<br/>
                We create feature-rich mock-ups<br/>
                for web & mobile applications.
              </p>
            </div>
          </div>
      } onScroll={true} duration={0.5} delay={0.3} slideFromDirection={"left"} fadeIn={true}/>
          <div className="learnMoreContainer mob">
            <a href="/services/" className="monitorLearnMore mob">Learn More.</a>
          </div>
        </div>
      </div>

      <div id="circleContainer" className="mob">
        <AnimatedElement elem={
        <div id="firstCircle" className="whiteCircle mob">
          <h1>Transparency is Key.</h1>
          <p>
            We keep you involved every step of the way. It's your idea, your passion,
            and your money so we make sure you're getting exactly what you want.
          </p>
        </div>
    } delay={0.25} fadeIn={true} duration={0.5} onScroll={true} />
        <AnimatedElement elem={
        <div id="secondCircle" className="whiteCircle mob">
          <h1>The Real MVPs.</h1>
          <p>
            We make sure your MVPs are truly minimal. Our team has first-hand
            experience with MVPs (not just with our clients), most team members
            have been an equity holder or core team member of a start-up in the past.
          </p>
        </div>
    } delay={0.25} fadeIn={true} duration={0.5} onScroll={true} />
        <AnimatedElement elem={
        <div id="thirdCircle" className="whiteCircle mob">
          <h1>Pure Speed.</h1>
          <p>
            Your product should be in the hands of customers and investors, not in the lab.
            So we get your MVP out the door ASAP, generally in 2-3 months.
          </p>
        </div>
    } delay={0.25} fadeIn={true} duration={0.5} onScroll={true} />
        <AnimatedElement elem={
        <div id="fourthCircle" className="whiteCircle mob">
          <h1>Grow It.</h1>
          <p>
            We create serverless applications that scale with your company.
            Meaning you don't have to worry about paying for servers that aren't
            always in use and you'll be able to handle any amount of traffic from day one.
          </p>
        </div>
    } delay={0.25} fadeIn={true} duration={0.5} onScroll={true} />
      </div>

      <div id="recentContainer" className="mob">
        <h1>Recent Work.</h1>

        <div id="worksContainer" className="mob">
          <div id="reimage" className="mob">
            <AnimatedElement elem={
            <a href="/casestudy/">
              <img src="/reimageIT.png"/>
            </a>
            } onHover={true} />
          </div>
          <div id="matchPoint" className="mob">
            <AnimatedElement elem={
            <a href="/our-work/">
              <img src="/MPfull_1.png"/>
            </a>
            } onHover={true} />
          </div>
        </div>

        <a href="/our-work/" className="recentLearnMore mob">Learn More.</a>
      </div>

      <div id="contactContainer" className="mob">
        <div className="contactHeader">Let's Talk</div>
        <div className="contactText mob">
          Interested in starting a project?<br/>
          Are you an amazing React or React Native developer?<br/>
          <strong>Get in touch.</strong>
        </div>
        <EmailForm id={0} containerId="form-container-alt"/>

        <Footer color="black"/>
      </div>
    </Layout>
  )
}
