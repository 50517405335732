import React from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class AnimatedElement extends React.Component {

  // ----- Props (* denotes required) -----
  // elem* : JSX Element         = The element that is being affected.
  // duration : Int              = How long the animation is going to play.
  // delay : Int                 = How long to wait before playing the animation.
  // className : String          = A custom className to give to the div.
  // onScroll : Boolean          = Whether to run this animation when it enters the viewport.
  // slideFromDirection : String = ("left", "right", "up", "down") Which direction to slide in from.
  // fadeIn : Boolean            = Whether to have a fade in on the elem.
  // rotation : Int              = How many degrees to rotate the element from.
  // onHover : Boolean           = Whether the element should do something onHover.

  constructor(props) {
    super(props);
    this.state = {
    };

    this.titleRef = null;

    // If duration is undefined, set this.duration to 1.
    // Otherwise, set it to the given duration.
    this.props.duration === undefined ? this.duration = 1 : this.duration = this.props.duration;

    // If delay is undefined, set this.delay to 0.
    // Otherwise, set it to the given delay.
    this.props.delay === undefined ? this.delay = 0 : this.delay = this.props.delay;

    // If this.props.onScroll is not set or is set to false, this.onScoll is false.
    this.onScroll = !(this.props.onScroll === undefined || this.props.onScroll === false);

    // If the slideFromDireciton prop is up or down, change slideVertical to correspond.
    this.slideVertical = 0;
    // If the slideFromDireciton prop is left or right, change slideHorizontal to correspond.
    this.slideHorizontal = 0;
    if (this.props.slideFromDirection == "up") {
      this.slideVertical = 50;
    }
    else if (this.props.slideFromDirection == "down") {
      this.slideVertical = -50;
    }
    else if (this.props.slideFromDirection == "left") {
      this.slideHorizontal = -50;
    }
    else if (this.props.slideFromDirection == "right") {
      this.slideHorizontal = 50;
    }
    else {
      console.warn("Incorrect input for AnimatedElement \"slideFromDirection\" prop.");
    }

    this.rotation = this.props.rotation;


    this.fadeIn = !(this.props.fadeIn === undefined || this.props.fadeIn === false);

    gsap.registerPlugin(ScrollTrigger);

  }

  componentDidMount() {

    // If onHover is true,
    if (this.props.onHover) {
      this.titleRef.addEventListener('mouseenter', e => {
        gsap.to(this.titleRef, 0.5,
          {
            scale: 1.2,
            transformOrigin: "50% 50%",
            ease: "bounce.out",
          });
      });

      this.titleRef.addEventListener('mouseleave', e => {
        gsap.to(this.titleRef, 0.5,
          {
            scale: 1,
            transformOrigin: "50% 50%",
            ease: "bounce.out",
          });
      });
    }

    gsap.from(this.titleRef, this.duration,
      {
        scrollTrigger: this.onScroll ? this.titleRef : null,
        opacity: this.fadeIn ? 0 : 1,
        delay: this.delay,
        y: this.slideVertical,
        x: this.slideHorizontal,
        rotation: this.rotation,
      });
  }


  render() {
    return (
      <div ref={div => this.titleRef = div} className={this.props.className}>
        {this.props.elem}
      </div>
    )
  }
}
