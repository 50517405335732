import React from "react"
import emailjs from 'emailjs-com';
import "../stylesheets/components/email-form.css"

export default class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: ''
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  componentDidMount() {
    document.getElementById('contact-form').addEventListener('submit', this.sendEmail.bind(this));
  }

  handleNameChange(event) {
    this.setState({name: event.target.value});
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handleSubjectChange(event) {
    this.setState({subject: event.target.value});
  }

  handleMessageChange(event) {
    this.setState({message: event.target.value});
  }

  resetForm() {
   this.setState({name: '', email: '', subject: '', message: ''})
  }

  sendEmail(event) {
    event.preventDefault();

    // Makes sure name isn't empty.
    if(this.state.name == "") {
      alert("Please provide a name.");
      return;
    }

    //Checks the necessary things for an email.
    if(!this.state.email.includes("@") ||
       !this.state.email.includes(".") ||
        this.state.email.includes(" ")) {
        alert("Incorrectly formatted email.\n Please input a valid email.");
        return;
    }

    // Makes sure message isn't empty.
    if(this.state.message == "") {
      alert("Please provide a message.");
      return;
    }

    var templateParams = {
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message
    };
    emailjs.send('service_46a36ro', 'template_yprgeo8', templateParams, "user_Cpg6dmj9c5hWvrbDQDEAE");
    this.resetForm();
    alert("Message sent!");
  }

  render() {
    return (
      <div id={this.props.containerId}>
        <form id="contact-form">
          <input type="text" id="name" className="mob" name="name" placeholder="Name" spellcheck="false" autocomplete="off" value={this.state.name} onChange={this.handleNameChange}></input>
          <br/>
          <input type="text" id="email" className="mob" name="email" placeholder="Email" spellcheck="false" autocomplete="off" value={this.state.email} onChange={this.handleEmailChange}></input>
          <br/>
          <input type="text" id="subject" className="mob" name="subject" placeholder="Subject" spellcheck="false" autocomplete="off" value={this.state.subject} onChange={this.handleSubjectChange}></input>
          <br/>
          <textarea id="message" className="mob" name="message" placeholder="Message" spellcheck="false" autocomplete="off" value={this.state.message} onChange={this.handleMessageChange}></textarea>
          <br/>
          <button className="mob" onClick={this.sendEmail}>Send Message</button>
        </form>
      </div>
    )
  }
}
